<script
    lang="ts"
    setup
>
    type Props = {
        hideButton?: boolean
        closeDisabled?: boolean
        rightSideClass?: string
    }

    type Emit = {
        (event: 'close'): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()
</script>

<template>
    <div class="relative flex items-center min-h-[var(--chat-header-height)] px-2">
        <AppButtonIcon
            v-if="!props.hideButton"
            key="button"
            small
            :disabled="props.closeDisabled"
            @click="emit('close')"
        >
            <AppIconArrowLeft size="20" />
        </AppButtonIcon>

        <div
            v-if="$slots['default']"
            key="title"
            class="
                pointer-events-none
                absolute
                left-0
                top-0
                w-full
                h-full
                flex
                items-center
                justify-center
                mx-auto
                text-[16px]
                font-medium
            "
        >
            <slot name="default" />
        </div>

        <div
            v-if="$slots['right']"
            key="right"
            :class="[
                `
                    absolute
                    right-4
                    top-0
                    h-full
                    flex
                    items-center
                    justify-center
                `,
                props.rightSideClass,
            ]"
        >
            <slot name="right" />
        </div>
    </div>
</template>
